const routes = [
  {
    path: "",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "home",
        component: () => import("src/pages/Home.vue"),
      },
      {
        path: "products",
        component: () => import("src/pages/Products.vue"),
      },
      {
        path: "products/form",
        component: () => import("src/pages/Form.vue"),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
];

export default routes;
