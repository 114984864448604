import { boot } from "quasar/wrappers";
import { LocalStorage } from "quasar";
import { useAuthStore } from "src/stores/auth";

export default boot(async ({ app, router, redirect }) => {
  // Load the auth store
  const aStore = useAuthStore();

  router.beforeEach(async (to, from) => {
    // If refreshToken in query params then assume redirected from login page
    if (!aStore.isAuthenticated && to.query.refresh_token) {
      await aStore.validateAuth(to.query.refresh_token);
    }
    // If qtoken exists in local storage use it
    else if (!aStore.isAuthenticated && LocalStorage.has("qtoken")) {
      let token = LocalStorage.getItem("qtoken");
      if (token && token.RefreshToken) {
        await aStore.validateAuth(token.RefreshToken);
      }
    }

    // Check authentication status and route accordingly
    if (aStore.isAuthenticated) {
      console.log("Authenticated");
      return true;
    } else {
      if (!process.env.DEV) {
        console.log("Not Authenticated: Redirecting");
        redirect(
          "https://ptecunstesting.auth.us-east-1.amazoncognito.com/login?client_id=5mufnucoj1ftm0n35jfs4n91ve&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Frouting.powertecuns.com"
        );
      } else {
        console.log("redirect");
        return true;
      }
    }
  });
});

// http://localhost:8080/contractors?user=jacksonbowe&refreshToken=eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Edm3kKwB7VlUV_6-2dMKzgpcrfjvUscjoKHNivCTT5rYbJAISfgz5Oyuj9Kuko3-yZK1b_O7n4cUYlqv7v-OqtyhsmtVmxMpiuDeDWCHwzx59FLyPlj-lcU23dxwVC5eFYFNdJOf69VgAMnQ-OTRLrKU-8SuvkqaTlprYxKsO1nN_wXhUeVeZrJKDqr14Hg3Jot_ttj6CQ2c4IFR4b4XVe8KhYdrXEChq7fhK2rQaNdWid94TapMI-6_X87sETuu1VrFKGIW1l6MjaICmdu7puo5mMIOsBnrvZaBEweYC0CKzCYmFIEkQUWPJD3eqtkGqIsGXl3vx1ozFTqYZ_En9g.gFKYmjVWeElSjcLh.GTM9ze6rlFPgH5DGiHug75m95SUAvVTzXlw0C8-gS2PnO4nE4x-Vv8GQVc6NSx3EKmbY3wc5Jt3N3V3x--3XjFr5p7t9SzZlhtaik_D7tqlHXQ0eMOqmsl4_TwQMC5uWBK0-RDnVyUr_KOZiZLb5Twgph8sPPrF_VO29bwMDTlxK0Bm5z_az7mlwQZBqQhupo6d4bSoCG_UOcfJkpsuTsoKwUelDpFAli4cNn8RGtk46gFsihZA6MVaal99fU_Lq0QGoW2wsucplPQg6dasECcMU62CDXO0c0-f1gSopCAa0aoN-G_Z3p9MR3KZcuyeo93PfSSxia-9HGNYMIhs35n9bwCaQMoNSc0GztxaXg7JogOBNDtEY9jIomWdvyn_tiEvq_0E3gtDZNFIUdKLmecm8651H19l9_v7uhwV7PnExepT9sx9gzob1C4TwOBK9QpSdbOyDJFIHobYmserQTrqoL2WS0DobVoVhRiExCpM3B6UPivdCor4LdFF3pXkScalKLwMhiK3sfxDs_1DyCMAKz5qor4_0v8B8SgtA94NzxuvkxIMG8IweYZ-uBYgsvtaG34Ia7H-zK_4zHT4vffCurmougtRpMoS0Uy7Xl97FpIpqpn9QHSY3G_BWuSMoCF0otCXSG5U8C-T-4SUyZyM1P1moA3TcMVvwjenigPR5nygA_lOJa-yJ3BxmEctvKafJ8rKtDID0UFF1YGG7GBSfIp53_EpN4nGS5TvZWnLO2io6piIJIJDb2Ehf73LdGjeMxIjuaaqVtUgByeMc22-k4hgfcw9RLV101q9Kc97ytHaawhJzRkgZRen1--MSJPSq4j4yF7n450t5OyAofhjx3mCp_-351QfDAXMXDZ6aZAqt0SEP-3ZygN5nEeaZNfFvyST5Mw7_YSyF4P0MDY5tlPUhvhY1M3rzUVskEcQphR8s2MqbpRaWshdnbd7m2grk7REOpFdP2fTspQcnHeyPM41UahhNmYFA1efeTq6PZT6rW90WFTo0mMXZhHnhnMFZ3reyaWDbx747SkwpkHZzjmCVepzSE1nvaESn4VnaSNpdosX8KS2Ln9mQ7hhBWFcnWagwbdeSm389A4KnTt4yDRqr3mL-yahvKWFLU-u2A0X7ehR31PeR_sT8WvLCh97pyuNF38Deyx3FKBuwxXScOEcD2L0TmETOO9eGKsQuaEWmYlMgOx2hvhG5C-L6mj5v-fNUDMPuZhuruZJdI9PBkGZt8WUFXdRXbyn9vbY6GoO85h1mKMvheNKsCNjiaAu_6cSdtVFiSw.TI_wIt0CyGdxvS4OlQ8nbA
