import { defineStore } from "pinia";
import { LocalStorage } from "quasar";
import { uns } from "src/boot/axios";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    me: "",
    token: "",
    isAuthenticated: false,
  }),

  getters: {
    doubleCount(state) {
      return state.counter * 2;
    },
  },

  actions: {
    async validateAuth(refreshToken) {
      return await uns
        .get("auth/token", { params: { refreshToken: refreshToken } })
        .then((response) => {
          if (!response.error) {
            let token = response.data.AuthenticationResult;

            token.RefreshToken = refreshToken;
            this.token = token;
            console.log("Authenticating");
            this.isAuthenticated = true;
            LocalStorage.set("qtoken", token);
          }
          return response;
        })
        .catch((e) => {
          console.log("error", e);
          return { error: e };
        });
    },
    logout() {
      LocalStorage.remove("qtoken");
      window.location.reload();
    },
  },
});
